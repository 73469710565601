import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1024 720">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000"  transform="">

	
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M495.000000,370.042419 
	C505.471527,369.680389 515.514282,371.020050 525.413879,369.018555 
	C548.308228,364.389709 563.754395,345.501221 563.916321,322.067383 
	C564.077209,298.785889 548.711731,279.390625 526.137634,274.376984 
	C522.878235,273.653076 519.605408,273.248260 516.242188,273.264374 
	C501.575958,273.334747 486.906555,273.453094 472.244324,273.201538 
	C467.542328,273.120911 465.738342,274.416412 465.900970,279.358002 
	C466.229736,289.346741 465.889954,299.355713 466.033813,309.353363 
	C466.086945,313.046631 464.962341,314.996765 460.978149,314.933716 
	C448.651367,314.738678 436.321747,315.237946 423.990692,314.621155 
	C420.765167,314.459808 419.634949,313.462891 419.655762,310.227478 
	C419.759827,294.061707 419.776733,277.893982 419.618530,261.728943 
	C419.581299,257.927277 421.137909,256.826508 424.658112,256.835876 
	C455.657410,256.918365 486.672882,256.290894 517.652039,257.092621 
	C550.991089,257.955444 577.514954,283.861755 580.157104,316.585907 
	C582.733398,348.493835 561.013489,377.622406 529.136230,384.848206 
	C524.930969,385.801453 520.629883,386.324707 516.261353,386.301605 
	C501.261932,386.222290 486.261383,386.331848 471.262360,386.219666 
	C467.881653,386.194397 465.942139,386.861755 465.955109,390.832367 
	C465.967346,394.576813 467.422668,395.756348 471.040558,395.665131 
	C488.360931,395.228485 505.691467,396.082367 523.015137,395.011536 
	C554.097229,393.090271 581.448730,369.409454 588.421631,338.603149 
	C598.076477,295.948334 570.456665,255.417435 527.177979,248.582596 
	C522.767761,247.886124 518.200500,248.060562 513.705505,248.046539 
	C494.539246,247.986816 475.371704,247.898087 456.207001,248.075653 
	C451.282196,248.121292 449.428650,246.568863 449.627441,241.449539 
	C450.044830,230.702209 448.373444,231.740417 459.198669,231.708008 
	C478.198334,231.651108 497.198547,231.613144 516.197998,231.692032 
	C562.760132,231.885361 600.575806,265.589569 606.117432,311.752808 
	C611.669006,357.999420 578.544006,402.194244 532.333191,410.310547 
	C525.899658,411.440521 519.414612,411.813263 512.943420,411.824921 
	C493.783356,411.859436 474.621338,412.209656 455.458832,411.519653 
	C451.154663,411.364685 449.410065,410.118286 449.694824,405.724915 
	C449.974487,401.410400 449.446625,397.038208 449.804962,392.735596 
	C450.215118,387.810913 448.726685,385.778900 443.456451,386.163055 
	C437.323029,386.610229 431.127197,386.119537 424.965851,386.295654 
	C421.191254,386.403595 419.590118,385.162170 419.630829,381.144653 
	C419.791229,365.313141 419.772034,349.478302 419.633820,333.646332 
	C419.601929,329.993805 420.842163,328.618378 424.534393,328.661133 
	C436.699249,328.802032 448.867798,328.791473 461.032776,328.652252 
	C464.449280,328.613129 465.949860,329.923615 465.938293,333.271820 
	C465.911224,341.104553 465.913116,348.937561 465.953705,356.770203 
	C465.969086,359.742096 464.461639,360.995087 461.626556,360.957642 
	C460.960480,360.948822 460.293854,360.987946 459.627380,360.999115 
	C449.787537,361.163940 449.869476,361.162048 449.673218,351.315918 
	C449.627686,349.030884 451.043640,345.602234 447.819763,344.779022 
	C444.329773,343.887817 440.493286,344.396576 437.046295,345.719910 
	C435.489105,346.317688 435.746979,348.233917 435.758667,349.741516 
	C435.798706,354.906738 436.110870,360.092407 435.752869,365.230804 
	C435.483429,369.098663 436.849731,370.128723 440.505493,370.102325 
	C458.502869,369.972321 476.501678,370.042603 495.000000,370.042419 
M449.725800,282.594299 
	C449.726746,272.649292 449.731567,272.745117 439.816193,273.243256 
	C436.841064,273.392731 435.630310,274.492126 435.746002,277.442444 
	C435.901550,281.410309 435.912750,285.385773 435.904663,289.357788 
	C435.884247,299.351135 435.860596,299.237579 445.831085,298.766296 
	C448.928314,298.619904 449.867889,297.340088 449.769958,294.493744 
	C449.644806,290.855530 449.729797,287.210052 449.725800,282.594299 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M491.422546,464.275818 
	C496.395874,467.631287 497.305023,472.237061 495.864990,477.220490 
	C494.416260,482.233948 490.289612,484.776489 485.380798,485.131073 
	C479.100952,485.584656 472.766113,485.264679 466.455170,485.305969 
	C463.937805,485.322418 462.852509,484.038208 462.853912,481.612396 
	C462.860504,470.151154 462.849030,458.689941 462.855774,447.228699 
	C462.857239,444.732208 464.208862,443.682526 466.631561,443.698669 
	C471.780457,443.733002 476.944397,443.461914 482.075745,443.764404 
	C493.043396,444.410858 497.270203,452.779175 491.594055,462.199677 
	C491.292358,462.700348 491.340454,463.411774 491.422546,464.275818 
M484.668304,475.280029 
	C486.743286,470.000580 484.349396,467.865753 477.253235,468.258545 
	C474.215546,468.426697 473.334229,469.789917 473.269196,472.602142 
	C473.201080,475.546967 474.217072,476.932831 477.259247,476.727875 
	C479.545563,476.573792 481.902893,477.007538 484.668304,475.280029 
M481.357208,452.856445 
	C478.565796,452.505280 475.335632,450.857361 473.578613,454.423035 
	C472.855499,455.890442 472.557190,458.441010 474.460144,459.331787 
	C477.031403,460.535431 480.091858,460.549927 482.411987,458.652985 
	C484.425842,457.006470 484.113922,454.904877 481.357208,452.856445 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M391.254120,472.553650 
	C388.435211,472.160828 387.070251,473.102539 387.094055,475.728821 
	C387.109131,477.390503 386.894501,479.063507 387.020813,480.713196 
	C387.317810,484.591278 385.298309,485.476654 381.847809,485.427734 
	C378.507599,485.380432 376.562622,484.722260 376.627777,480.747803 
	C376.804962,469.943237 376.761841,459.132690 376.627716,448.326721 
	C376.585205,444.904114 377.845642,443.463715 381.289612,443.631439 
	C387.419434,443.929962 393.576141,443.180450 399.696381,444.212097 
	C404.635773,445.044708 408.676300,447.083221 410.032562,452.255737 
	C411.572144,458.127472 411.370178,463.813446 406.763367,468.520020 
	C406.079529,469.218658 405.317719,469.841034 404.531006,470.553986 
	C405.943939,475.903320 410.930450,479.116211 412.373840,484.286987 
	C405.021912,486.886353 401.583466,485.781738 397.492645,479.969421 
	C395.694916,477.415161 394.399506,474.419769 391.254120,472.553650 
M388.301849,452.834442 
	C386.305756,456.050018 386.042480,459.770325 388.371002,462.392883 
	C390.379333,464.654877 393.901062,463.873352 396.802887,462.913086 
	C399.314545,462.081970 400.517944,460.168243 400.511200,457.647827 
	C400.504120,455.008759 398.937378,453.408020 396.425964,452.742950 
	C393.992065,452.098358 391.525543,452.252930 388.301849,452.834442 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M572.382080,477.196045 
	C567.803162,483.020691 561.896362,485.199799 555.074219,485.304108 
	C550.744568,485.370300 546.411255,485.250763 542.083313,485.360657 
	C539.095093,485.436554 537.858582,484.068146 537.863586,481.174744 
	C537.882935,470.015045 537.843018,458.855255 537.851990,447.695496 
	C537.853577,445.775879 538.116028,443.793884 540.627319,443.781189 
	C547.107788,443.748444 553.667114,442.859711 560.051147,444.260803 
	C575.007263,447.543243 580.683167,462.537170 572.382080,477.196045 
M548.309998,473.471527 
	C548.352844,474.665802 548.446472,475.784912 549.850220,476.284973 
	C556.891541,478.793518 565.410339,473.057678 565.732483,465.567169 
	C566.105286,456.898773 560.980713,452.020508 552.110291,452.328339 
	C549.352783,452.424011 548.213440,453.403259 548.266907,456.184296 
	C548.372437,461.677185 548.294861,467.173645 548.309998,473.471527 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M367.577271,453.753296 
	C369.580170,458.474609 370.401794,462.983215 369.668549,467.732605 
	C368.050568,478.212372 361.558228,484.478546 351.021729,485.215179 
	C346.050812,485.562744 341.036652,485.253601 336.043915,485.342926 
	C333.183136,485.394073 331.738708,484.312164 331.753540,481.271149 
	C331.808777,469.950897 331.771515,458.630096 331.743652,447.309601 
	C331.738373,445.156647 332.493896,443.720703 334.861938,443.726257 
	C342.000763,443.743073 349.210968,442.894257 356.218933,444.832703 
	C361.029114,446.163208 364.926453,448.919159 367.577271,453.753296 
M342.154480,464.502441 
	C342.173370,467.488098 342.198883,470.473755 342.208893,473.459442 
	C342.215424,475.409698 342.974487,476.609100 345.117004,476.643402 
	C349.942657,476.720581 354.598846,475.945526 357.532684,471.754822 
	C360.521942,467.484894 360.476990,462.675354 358.150146,458.044556 
	C356.269531,454.301910 348.884583,451.125641 344.695831,452.500488 
	C340.122223,454.001678 342.885315,458.136719 342.182800,461.040405 
	C341.993195,461.824036 342.153351,462.692322 342.154480,464.502441 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M588.355469,482.528229 
	C585.419617,487.202179 581.409180,484.762207 578.026611,485.084320 
	C576.838379,483.487305 577.663452,482.270447 578.156616,481.117859 
	C582.861267,470.121643 587.698608,459.181152 592.291260,448.138733 
	C593.715820,444.713623 595.822815,443.583710 599.491821,443.540283 
	C603.229065,443.496094 604.734924,445.232880 606.020996,448.297058 
	C610.456055,458.864685 615.096191,469.346161 619.631714,479.871857 
	C620.273132,481.360382 621.197205,482.816071 620.816956,484.546295 
	C613.230835,486.495605 611.812378,486.059570 608.880554,480.008362 
	C607.615723,477.397949 605.964233,476.181519 603.082458,476.393402 
	C601.098511,476.539307 599.086426,476.544037 597.102539,476.398682 
	C592.689270,476.075317 589.752136,477.808868 588.355469,482.528229 
M600.831848,467.572205 
	C604.607117,463.856720 600.038391,462.005127 599.376892,458.587891 
	C597.318481,461.582733 596.040771,463.962372 595.854370,466.736328 
	C597.160034,468.187622 598.732361,467.435394 600.831848,467.572205 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M675.460815,443.801514 
	C677.136658,444.216888 677.769958,445.209747 678.234680,446.285980 
	C683.382141,458.207092 688.516235,470.134033 693.612610,482.077057 
	C693.914551,482.784454 693.862610,483.642914 693.970520,484.397522 
	C686.538757,486.607544 684.766907,486.024628 681.995911,479.930206 
	C680.873047,477.460632 679.384460,476.283051 676.672302,476.401306 
	C673.846069,476.524536 671.006897,476.516510 668.179626,476.406433 
	C665.515076,476.302673 663.896606,477.427368 662.679565,479.805237 
	C659.464844,486.086121 658.439392,486.426453 650.870972,484.595245 
	C650.046265,482.617065 651.339966,481.053772 652.026855,479.440521 
	C656.136169,469.790710 660.669678,460.305023 664.364746,450.501892 
	C666.406677,445.084747 669.265320,442.517456 675.460815,443.801514 
M672.456970,459.275116 
	C669.871643,461.183563 669.554688,464.073883 668.781128,467.039337 
	C671.305847,467.789185 673.426514,468.179138 675.464355,466.607117 
	C675.333435,463.858887 674.205811,461.693237 672.456970,459.275116 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M418.650330,450.099030 
	C418.180939,445.643494 419.144928,443.235596 423.948547,443.582794 
	C427.072388,443.808594 428.476257,444.772552 428.427948,448.008453 
	C428.343445,453.669800 428.402557,459.337402 428.569489,464.997406 
	C428.795868,472.674072 431.718658,476.762604 436.684692,476.657806 
	C441.637024,476.553284 444.537109,472.264343 444.612335,464.785095 
	C444.669281,459.121460 444.771027,453.452301 444.586456,447.794281 
	C444.457031,443.827057 446.827850,443.681000 449.750916,443.622772 
	C452.756897,443.562897 454.446320,444.374084 454.374481,447.806519 
	C454.224609,454.966888 454.464539,462.136200 454.276947,469.294830 
	C454.022308,479.014313 446.676392,485.835846 436.680939,485.918579 
	C426.728058,486.000977 419.189209,479.281433 418.754822,469.574738 
	C418.472076,463.256226 418.670258,456.916199 418.650330,450.099030 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M505.430420,443.799438 
	C509.330719,445.270264 509.882904,448.949036 511.789093,451.578430 
	C513.021912,453.278961 513.523621,455.540771 515.822327,456.699432 
	C521.032471,451.744324 521.340698,439.611176 533.748474,444.617798 
	C532.631470,449.098724 529.923340,452.927582 527.472534,456.738617 
	C522.592285,464.327484 518.971558,472.107605 520.334351,481.467316 
	C520.791382,484.606018 518.820435,485.527222 516.037659,485.346069 
	C513.624939,485.189026 510.187317,485.775818 510.779816,481.966919 
	C512.774109,469.146027 504.996490,459.907410 499.572906,449.772980 
	C498.661530,448.070007 497.029724,446.601837 497.636810,444.283173 
	C500.034454,443.208069 502.551483,443.827118 505.430420,443.799438 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M630.630920,455.595947 
	C630.009094,451.324402 626.946655,452.483124 624.685669,452.408722 
	C621.541748,452.305267 618.703247,452.583008 618.787598,447.913147 
	C618.873230,443.175018 621.908875,443.664368 624.927002,443.667084 
	C632.585571,443.673920 640.245483,443.755859 647.902161,443.636597 
	C651.080994,443.587067 652.348450,444.760590 652.344177,448.011078 
	C652.340027,451.224609 651.177124,452.363190 647.945068,452.411285 
	C640.828186,452.517181 640.846985,452.666809 640.864380,459.729736 
	C640.881653,466.721924 640.782532,473.715302 640.885620,480.705933 
	C640.948059,484.940582 638.514404,485.388367 635.068237,485.417267 
	C631.393127,485.448120 630.590271,483.721161 630.636108,480.505890 
	C630.752380,472.349609 630.660339,464.190369 630.630920,455.595947 
z"/>


</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
